import * as React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import * as styles from "./404.module.scss"

export default function About() {
  return (
    <Layout>
      <Header />
      <div className={styles.error}> 
        404 - wrong turn!
      </div>
    </Layout>
  )
}
